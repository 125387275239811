import BaseSelect from '@/components/common/base-select/base-select'
import { formatDate } from '@/utils/auth/common'
// import { validbusinessNo } from '@/utils/validate'
export const fromconfig = (content) => {
  return [
    {
      label: '业务编号：',
      prop: 'businessNo',
      // rules: [{ required: false, validator: validbusinessNo, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商：',
      prop: 'gysId',
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        options: content.gysData,
        filterable: true,
        selectedField: ['keyId', 'enterpriseName']
      }
    },
    {
      label: '流程名称：',
      prop: 'processDefinitionKey',
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        options: content.ProcessNameList,
        selectedField: ['dictId', 'dictName'],
        filterable: true
      }
    },
    {
      label: '发起时间：',
      prop: 'applicationdate',
      tag: 'el-date-picker',
      isHidden: content.fold,
      span: 6,
      attrs: {
        editable: false,
        isRange: true,
        type: 'daterange',
        rangeSeparator: '-',
        startPlaceholder: '开始时间',
        endPlaceholder: '结束时间',
        placeholder: '选择时间范围'
      }

    }
  ]
}
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '流程名称',
      prop: 'processName',
      'show-overflow-tooltip': true
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      'show-overflow-tooltip': true
    },
    {
      label: '供应商',
      prop: 'gysId',
      'show-overflow-tooltip': true,
      formatter: row => {
        const [obj] = content.gysData.filter(item => item.keyId === row.gysId)
        return obj ? obj.enterpriseName : ''
      },
      align: 'left'
    },
    {
      label: '发起时间',
      prop: 'processCreateTime',
      showOverflowTooltip: true,
      formatter: row => {
        return formatDate(row.processCreateTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    // {
    //   label: '办理人',
    //   prop: 'assigneeName',
    //   'show-overflow-tooltip': true,
    //   isHidden: content.type === 'DB'
    // },
    {
      label: '办理时间',
      prop: 'claimTime',
      'show-overflow-tooltip': true,
      isHidden: content.type === 'DB',
      formatter: row => {
        return formatDate(row.claimTime, 'YY-MM-DD hh:mm:ss')
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
