<template>
  <div class="pd15 concent">
    <BaseForm
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      :type="type"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="fromdata"
    >
    <div slot="btn_after">
      <base-button icon='iconfont iconzhankai' class="foldBtn" :class="{PutawayBtn:fold}" type='default' @click="fold=!fold"></base-button>
    </div></BaseForm>
    <Basetable
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :key="type"
      ref="tableData"
      :webPage="true"
      @pageIndexChange="pageIndexChange"
      @pageSizeChange="pageSizeChange"
      class="main-page-table"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <IconButton @click.prevent="jump(scope.row)" content="办理" icon="iconfont iconshanchu2"></IconButton>
        <IconButton @click.prevent="jump(scope.row)" content="查看" icon="iconfont iconqianshuxieyi1"></IconButton>
      </template>
    </Basetable>
  </div>
</template>
<script>
import BaseForm from '@/components/common/base-form//base-form'
import Basetable from '@/components/common/table/base-table/base-table'
import IconButton from '@/components/common/button/icon-button/icon-button'
import { fromconfig, columnsConfig } from './config.js'
import { workbenchApi } from '@/api/workbenchApi'
export default {
  components: { BaseForm, Basetable, IconButton },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      tableData: [{}],
      fold: false
    }
  },
  computed: {
    type () {
      return this.$route.query.type
    },
    columns () {
      return columnsConfig(this)
    },
    from () {
      return fromconfig(this)
    },
    api () {
      return workbenchApi
    }
  },
  methods: {
    // 时间处理
    getdate (val) {
      return (
        new Date(val).getFullYear() +
        '-' +
        (new Date(val).getMonth() + 1) +
        '-' +
        new Date(val).getDate()
      )
    },
    // 重置
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    // 查询
    handleFilter () {
      const params = {
        ...this.queryParas
      }
      if (params.applicationdate) {
        params.startTime = this.getdate(params.applicationdate[0])
        params.endTime = this.getdate(params.applicationdate[1])
      }
      if (this.type === 'DB') {
        this.api.getTodo(params).then((res) => {
          if (res.data) {
            this.tableData = res.data.records
          }
        })
      } else if (this.type === 'YB') {
        this.api.getDone(params).then((res) => {
          this.tableData = res.data.records
        })
      }
    },
    pageIndexChange (val) {
      this.queryParas.pageIndex = val
      this.handleFilter()
    },
    pageSizeChange (val) {
      this.queryParas.pageSize = val
      this.handleFilter()
    }
  },
  mounted () {

  },
  created () { this.handleFilter() }
}
</script>
<style lang="scss" scoped>
.pd15 {
  padding: 15px;
}
.concent {
  height: calc(100% - 60px);
}
</style>
